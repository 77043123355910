<template>
  <!-- 头部 -->
  <div class="common_center">
    <div class="banner"></div>
    <div class="icon_show">
      <el-breadcrumb style="font-size: 16px; margin-top: 40px;" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software' }">软件开发</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software/smartCity' }">智慧城市</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mobile_title" ref="serveSpan1">
        <p class="mobile_title_one">智慧城市发展趋势</p>
      </div>
      <div class="home_serve">
        <div class="serve_info">
          <div class="serve_box" ref="serveBox">
            <div class="serve_box_item" v-for="(item, index) in boxSrc" :key="index">
              <img class="serve_box_item_img" :src="item.src">
              <div class="serve_box_item_title">
                <span>{{item.title}}</span>
              </div>
              <div class="serve_box_item_after">
                <div class="after_item">
                  <span class="item_title">{{item.title}}</span>
                  <span class="item_info">{{item.info}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile_title" ref="mobileTitle">
          <p class="mobile_title_one">智慧城市解决方案</p>
        </div>
        <div class="case_nav" ref="caseNav">
          <div :class="items.id === navId ? 'case_nav_single_active' : 'case_nav_single'" v-for="(items, index) in bannerList" :key="index" @click="getList(index)">
            <span>{{items.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner_center">
      <img :src="bannerText.src" ref="bannerBg">
      <div class="banner_text" ref="bannerText">
        <p class="banner_text_title">{{bannerText.text}}</p>
        <p class="banner_text_box"></p>
        <p class="banner_text_info">{{bannerText.info}}</p>
      </div>
    </div>
    <div class="icon_show">
      <div class="mobile_title" ref="mobileTitle2">
        <p class="mobile_title_one">智慧城市可视化</p>
      </div>
      <div class="case_banner" ref="caseBanner">
        <el-carousel :interval="5000">
          <el-carousel-item v-for="(item, index) in caseInfo" :key="index">
            <img class="case_banner_box1" style="margin-right: 40px;" :src="item.src"/>
            <div class="case_banner_box2">
              <p class="case_banner_box2_one">{{item.title}}</p>
              <p class="case_banner_box2_tow">{{item.info}}</p>
            </div>
            <img class="case_banner_box1" :src="item.src1"/>
            <div class="case_banner_box2_2">
              <p class="case_banner_box2_2_one">{{item.title1}}</p>
              <p class="case_banner_box2_2_tow">{{item.info1}}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>

import icon1 from '@/assets/img/softWare/smartCity/city_adv1.jpg'
import icon2 from '@/assets/img/softWare/smartCity/city_adv2.jpg'
import icon3 from '@/assets/img/softWare/smartCity/city_adv3.jpg'
import icon4 from '@/assets/img/softWare/smartCity/city_adv4.jpg'
export default {
  name: 'smartCity',
  data () {
    return {
      navId: 1,
      boxSrc: [
        {
          src: icon1,
          title: '规划绿色公共空间',
          info: '绿色的城市也将有利于“碳中和”的实现，城市绿化带可以有效储存和消耗二氧化碳，而合理的绿色规划将有助于降低2-8度的气温，从而降低空调需求量，减少城市电力消耗。'
        },
        {
          src: icon2,
          title: ' 构建智慧健康社区',
          info: '在人工智能、GPS定位、大数据等前沿技术的支持下，未来城市有望构建更成熟的智慧健康社区，即利用数字技术赋能个人积极管理自身健康。'
        },
        {
          src: icon3,
          title: '实践循环经济与生产',
          info: '通过在产品和材料的生命周期结束时进行回收和再生，形成循环经济，发挥资源的使用价值。同时，增强城市的本地生产能力，如在屋顶或其他可利用土地上进行城市耕作，以提升城市应对粮食供应的韧性。'
        },
        {
          src: icon4,
          title: '人工智能实现自动化',
          info: '在AI、5G、大数据等技术的驱动下，城市将逐渐形成大规模连接的生态系统，拥有广泛的物联网、数字解决方案和应用程序。未来，依托区块链、云计算等技术，以数据洞察为驱动，智慧城市将提供城市的整体景观，'
        }
      ],
      bannerText: {},
      bannerList: [
        {
          title: '智慧政府',
          text: {
            text: '智慧政府解决方案',
            info: '充分利用数字孪生、云计算、大数据分析、物联网等新技术，以用户创新、大众创新、开放创新、共同创新为特征，全面提升政府管理科学化、精细化、智能化水平、形成整体协同、高效运行、精准服务、科学管理的“智慧政府”新模式。',
            src: require('@/assets/img/softWare/smartCity/city_method1.jpg')
          },
          id: 1
        },
        {
          title: '智慧交通',
          text: {
            text: '智慧交通解决方案',
            info: '利用物联网、智能分析技术、三维可视化技术与各系统的数据、实际业务场景融合，打造面向运营人员的有效支撑工具，实现智能交通全息的可视化感知，提升运营管理水平、提高事件响应效率',
            src: require('@/assets/img/softWare/smartCity/city_method2.jpg')
          },
          id: 2
        },
        {
          title: '智慧金融',
          text: {
            text: '智慧金融解决方案',
            info: '以大数据、可视化技术助力金融科技运营发展，发展统筹管控IT、IOT数据，共创数字化、场景化、智能化的“智慧金融”新时代',
            src: require('@/assets/img/softWare/smartCity/city_method3.jpg')
          },
          id: 3
        },
        {
          title: '智慧能源',
          text: {
            text: '智慧能源解决方案',
            info: '结合全栈可视化能力，与能源业务深度融合，连接、智能、高效的数字能源，赋能电力、石油化工、煤炭全产业提质增效，推动行业数字化转型',
            src: require('@/assets/img/softWare/smartCity/city_method4.jpg')
          },
          id: 4
        },
        {
          title: '智慧公安',
          text: {
            text: '智慧公安解决方案',
            info: '整合多维数据，实现数据资源一体化，为智慧公安可视化建设提供坚实的底座，满足监视预警、应急指挥调度、日常演练等多维度使用场景',
            src: require('@/assets/img/softWare/smartCity/city_method5.jpg')
          },
          id: 5
        },
        {
          title: '智慧教育',
          text: {
            text: '智慧教育解决方案',
            info: '建立校园数字空间运营体系，跨部门整合学校各类资源信息来制定更好的决策，从教学资源、教学环境、教学服务、虚拟校园等多维度创新教育管理理念',
            src: require('@/assets/img/softWare/smartCity/city_method6.jpg')
          },
          id: 6
        },
        {
          title: '智慧医疗',
          text: {
            text: '智慧医疗解决方案',
            info: '通过软件定义方式对医院进行信息聚合、数字建模，三维映射，搭建一个智能化的数字空间，借助数据治理、知识图谱、轻量建模技术，提升医院的运营管理能力',
            src: require('@/assets/img/softWare/smartCity/city_method7.jpg')
          },
          id: 7
        },
        {
          title: '智慧企业',
          text: {
            text: '智慧企业解决方案',
            info: '基于物联网、云计算等先进技术，打造园区综合运营管理新模式，助力企业能够获取实时洞察运营情况并快速采取行动，推动数字化转型，进而实现由内而外的认知型企业转型',
            src: require('@/assets/img/softWare/smartCity/city_method8.jpg')
          },
          id: 8
        }
      ],
      caseInfo: [
        {
          src: require('@/assets/img/softWare/smartCity/city_case1.jpg'),
          src1: require('@/assets/img/softWare/smartCity/city_case2.jpg'),
          title: '智慧路灯1',
          info: '该系统面向智慧城市的各类智慧停车场景解决停车场管理难、车位占用率低、车主停车难等问题。',
          title1: '智慧停车2',
          info1: '智慧路灯作为智慧城市的主要载体，在实现人、物、城市之间无缝连接与协同联动的智能自感知，对政府服务多种城市需求中发挥着重要的作用。'
        },
        {
          src: require('@/assets/img/softWare/smartCity/city_case2.jpg'),
          src1: require('@/assets/img/softWare/smartCity/city_case1.jpg'),
          title: '智慧停车3',
          info: '该系统面向智慧城市的各类智慧停车场景解决停车场管理难、车位占用率低、车主停车难等问题。',
          title1: '智慧路灯4',
          info1: '智慧路灯作为智慧城市的主要载体，在实现人、物、城市之间无缝连接与协同联动的智能自感知，对政府服务多种城市需求中发挥着重要的作用。'
        },
        {
          src: require('@/assets/img/softWare/smartCity/city_case1.jpg'),
          src1: require('@/assets/img/softWare/smartCity/city_case2.jpg'),
          title: '智慧路灯5',
          info: '该系统面向智慧城市的各类智慧停车场景解决停车场管理难、车位占用率低、车主停车难等问题。',
          title1: '智慧停车6',
          info1: '智慧路灯作为智慧城市的主要载体，在实现人、物、城市之间无缝连接与协同联动的智能自感知，对政府服务多种城市需求中发挥着重要的作用。'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.bannerText = this.bannerList[0].text
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getList (index) {
      this.navId = index + 1
      this.bannerText = this.bannerList[index].text
    },
    // 滚动触发动画
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.serveBox.offsetTop - 650) {
        if (this.$refs.serveBox.className === 'serve_box') {
          this.$refs.serveSpan1.className = this.$refs.serveSpan1.className + ' animated bounce'
          this.$refs.serveBox.className = this.$refs.serveBox.className + ' animated bounceInDown'
        }
      }
      if (__currentScroll >= this.$refs.mobileTitle.offsetTop - 750) {
        if (this.$refs.mobileTitle.className === 'mobile_title') {
          this.$refs.mobileTitle.className = this.$refs.mobileTitle.className + ' animated fadeInUpBig'
          this.$refs.caseNav.className = this.$refs.caseNav.className + ' animated fadeInUpBig'
        }
      }
      if (__currentScroll >= this.$refs.bannerText.offsetTop - 650) {
        if (this.$refs.bannerText.className === 'banner_text') {
          this.$refs.bannerBg.className = this.$refs.bannerBg.className + ' animated fadeInDown'
          this.$refs.bannerText.className = this.$refs.bannerText.className + ' animated zoomInRight'
        }
      }
      if (__currentScroll >= this.$refs.mobileTitle2.offsetTop - 665) {
        if (this.$refs.mobileTitle2.className === 'mobile_title') {
          this.$refs.mobileTitle2.className = this.$refs.mobileTitle2.className + ' animated bounce'
          this.$refs.caseBanner.className = this.$refs.caseBanner.className + ' animated lightSpeedIn'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.common_center{
  overflow: hidden;
  .banner {
    height: 500px;
    background: url('~@/assets/img/softWare/inner-banner.jpg') no-repeat center;
    background-size: 100% 100%;
  }
  .icon_show {
    width: 1400px;
    margin: auto;
    overflow: hidden;
    .mobile_title{
      margin-top: 60px;
      text-align: center;
      font-family: MicrosoftYaHei;
      color: #333333;
      .mobile_title_one {
        font-size: 36px;
        letter-spacing: 4px;
        color: #333333;
      }
    }
    .home_serve{
      width: 100%;
      margin-top: 60px;
      overflow: hidden;
      .serve_info{
        width: 1400px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .serve_box{
          height: 424px;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          overflow: hidden;
          .serve_box_item{
            width: 335px;
            height: 420px;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;
            transition: all .5s ease-in-out;
            .serve_box_item_img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
            .serve_box_item_after{
              width: 100%;
              height: 100%;
              position: absolute;
              background-color: #3179ff;
              top: 420px;
              left: 0;
              display: flex;
              align-items: center;
              .after_item {
                width: 240px;
                height: 340px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: auto;
                .item_title{
                  font-size: 24px;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  height: 63px;
                  border-bottom: 2px solid #fff;
                }
                .item_info{
                  font-size: 16px;
                  line-height: 30px;
                  letter-spacing: 0px;
                  color: #ffffff;
                  text-align: center;
                }
              }
            }
          }
          img {
            position: absolute;
          }
          .serve_box_item:hover {
            .serve_box_item_img{
              top: -420px;
              transition: all 1s ease-in-out;
            }
            .serve_box_item_title {
              // display: none;
            }
            .serve_box_item_after{
              width: 100%;
              height: 100%;
              top: 0;
              transition: all 1s ease-in-out;
            }
          }
          .serve_box_item_title{
            position: absolute;
            bottom: 0;
            width: 335px;
            height: 60px;
            background-color: rgba(0,0,0,.4);
            display: flex;
            justify-content: center;
            span{
              color: #fff;
              font-size: 24px;
              line-height: 60px;
            }
          }
        }
      }
      .case_nav{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 35px;
        .case_nav_single{
          position: relative;
          height: 55px;
          font-family: MicrosoftYaHei;
          font-size: 30px;
          color: #666666;
          cursor: pointer;
        }
        .case_nav_single:hover{
          color: #0773fc;
          outline: none;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0px;
            left: 18.5px;
            right: 20.5px;
            height: 2px;
            background-color: #0773fc;
          }
        }
        .case_nav_single_active{
          position: relative;
          height: 55px;
          font-family: MicrosoftYaHei;
          font-size: 30px;
          color: #0773fc;
          outline: none;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0px;
            left: 18.5px;
            right: 20.5px;
            height: 2px;
            background-color: #0773fc;
          }
        }
      }
    }
    .case_banner{
      height: 550px;
      width: 100%;
      margin-top: 60px;
      .case_banner_box1{
        width: 680px;
        height: 382px;
        border-radius: 8px 8px 0px 0px;
        position: relative;
        transform: scale(1);
        transition: all .5s ease-in-out;
      }
      .case_banner_box2{
        width: 658px;
        height: 99px;
        background-color: #fff;
        position: absolute;
        top: 380px;
        border: solid 1px #f0f0f0;
        border-top: none;
        font-family: MicrosoftYaHei;
        letter-spacing: 1px;
        color: #333333;
        padding-left: 20px;
        .case_banner_box2_one{
          font-size: 24px;
          margin: 10px 0 5px 0;
        }
        .case_banner_box2_tow{
          width: 575px;
          font-size: 16px;
        }
      }
      .case_banner_box2_2{
        width: 658px;
        height: 99px;
        background-color: #fff;
        position: absolute;
        top: 380px;
        left: 720px;
        border: solid 1px #f0f0f0;
        border-top: none;
        font-family: MicrosoftYaHei;
        letter-spacing: 1px;
        color: #333333;
        padding-left: 20px;
        .case_banner_box2_2_one{
          font-size: 24px;
          margin: 10px 0 5px 0;
        }
        .case_banner_box2_2_tow{
          width: 575px;
          font-size: 16px;
        }
      }
    }
    .el-carousel{
      width: 100%;
      height: 100%;
      ::v-deep.el-carousel__container{
        height: 100%!important;
      }
      ::v-deep.el-carousel__indicators--horizontal{
        left: 47.2%;
        transform: translateX(0%);
        .el-carousel__button{
          width: 20px;
          height: 20px;
          background-color: #d7d7d7;
          border-radius: 12px;
        }
      }
    }
  }
  .banner_center {
    height: 500px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .banner_text{
      width: 1400px;
      position: absolute;
      left: 260px;
      top: 120px;
      font-family: MicrosoftYaHei;
      .banner_text_title{
        font-size: 50px;
        color: #ffffff;
      }
      .banner_text_box{
        width: 60px;
        height: 4px;
        background-color: #ffffff;
        margin: 40px 0 50px 0;
      }
      .banner_text_info{
        width: 975px;
        font-size: 20px;
        color: #e2e2e2;
      }
    }
  }
}
</style>
