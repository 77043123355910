<template>
  <div>
    <!-- 头部 -->
    <div class="company">
      <div class="banner">
      </div>
      <div class="company_info">
        <div class="company_module1">
          <el-breadcrumb class="company_module1_titile" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/aboutZC/company' }">公司简介</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- <span class="company_module1_text" ref="companyModule1Text">COMPANY PROFILE</span> -->
          <span class="company_module1_name" ref="companyModule1Name">公司简介</span>
        </div>
        <div class="company_module2" ref="companyModule2">
          <div  class="company_module2_box">
            <div class="company_module2_box_text">
              <p class="box_text_time">Since 2019</p>
              <p class="box_text_title">山东中曹软件服务有限公司</p>
              <p class="box_text_info">山东中曹软件服务有限公司（以下简称“中曹软件”）是中国领先的软件与信息技术服务商，打造一流的人力资源服务平台，致力于成为具有全国影响力的数字技术服务领导企业，企业数字化转型可信赖合作伙伴。2019年，公司成立于济南，服务全国市场。目前，员工50余人。</p>
              <p class="box_text_info"> 在互联网的信息浪潮中，浩太软件根据企业所处行业、发展战略及阶段、企业竞争力、企业资源、团队状况、领导人思维等各个方面进行综合评估，给出全面的软件策划方案。</p>
            </div>
            <div class="company_module2_box_img"></div>
            <img src="@/assets/company/img.jpg">
          </div>
        </div>
      </div>
      <div class="company_good">
        <div class="company_good_info">
          <div class="company_good_info_module1">
            <!-- <span class="company_good_info_module_title" ref="companyGoodInfoTitle">COMPANY ADVANTAGES</span> -->
            <span class="company_good_info_module_text" ref="companyGoodInfoText">公司优势</span>
          </div>
          <div class="company_good_info_module2" ref="companyGoodInfoModule2">
            <div :class="isVal === index ? 'company_good_info_module2_box box_ts' : 'company_good_info_module2_box'"  v-for="(item, index) in goodInfo" :key="index" @mouseover="hoverModule(index)" @mouseout="isVal = null">
              <img :src="isVal === index ? item.hoverSrc : item.src">
              <span class="company_good_info_module2_box_span1">{{item.title}}</span>
              <span class="company_good_info_module2_box_span2">{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'companyIndex',
  data () {
    return {
      isVal: null,
      index: null,
      goodInfo: [
        { src: require('@/assets/company/good1.png'), hoverSrc: require('@/assets/company/good1_1.png'), title: '深刻理解客户需求', text: '累积各行业的开发经验，为您的企业快速提供技术解决方案。开发团队完全致力于您的项目，因此他们可以完全专注于交付您的需求。' },
        { src: require('@/assets/company/good2.png'), hoverSrc: require('@/assets/company/good2_2.png'), title: '精细产品开发测试', text: '从原型图效果设计、UI页面设计效果到app前端应用开发包括（、框架设计、接口开发 、数据库开发、后台服务端开发及测试联调的全方位服务。' },
        { src: require('@/assets/company/good3.png'), hoverSrc: require('@/assets/company/good3_3.png'), title: '项目质量与交付', text: '我们的代码是在考虑最佳实践的前提下编写的，并且由于外部代码的审查和持续集成，团队技术人员有丰富的开发经验，保证项目的质量与交付。' },
        { src: require('@/assets/company/good4.png'), hoverSrc: require('@/assets/company/good4_4.png'), title: '贴心的产品售后服务', text: '系统托管维护，客户满意度是项目内审的重要衡量标准，后期有专人维护，保障安全运行及定期备份，开发软件让客户用的安心放心。' }
      ],
      friendInfo: [
        { src: require('@/assets/company/friend1.png') },
        { src: require('@/assets/company/friend2.png') },
        { src: require('@/assets/company/friend1.png') },
        { src: require('@/assets/company/friend2.png') },
        { src: require('@/assets/company/friend1.png') },
        { src: require('@/assets/company/friend2.png') },
        { src: require('@/assets/company/friend1.png') },
        { src: require('@/assets/company/friend2.png') },
        { src: require('@/assets/company/friend1.png') },
        { src: require('@/assets/company/friend2.png') }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    const mouseDomArr = document.querySelectorAll('.company_friend_box_module2_img')
    mouseDomArr.forEach(item => {
      item.addEventListener('mouseover', () => {
        item.className += ' tss'
      })
      item.addEventListener('mouseout', () => {
        item.className = 'company_friend_box_module2_img'
      })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    hoverModule (val) {
      this.isVal = val
    },
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.companyModule1Name.offsetTop - 550) {
        if (this.$refs.companyModule1Name.className === 'company_module1_name') {
          this.$refs.companyModule1Name.className = this.$refs.companyModule1Name.className + ' animated slideInLeft'
          this.$refs.companyModule2.className = this.$refs.companyModule2.className + ' animated fadeInUpBig'
        }
      }
      if (__currentScroll >= this.$refs.companyGoodInfoText.offsetTop - 850) {
        if (this.$refs.companyGoodInfoText.className === 'company_good_info_module_text') {
          this.$refs.companyGoodInfoText.className = this.$refs.companyGoodInfoText.className + ' animated slideInLeft'
          this.$refs.companyGoodInfoModule2.className = this.$refs.companyGoodInfoModule2.className + ' animated fadeInUpBig'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tss {
  transform:rotateX(360deg);
  transition: all 1s;
}
.company{
  width: 100%;
  min-width: 1400px;
  .banner{
    width: 100%;
    height: 500px;
    background: url('~@/assets/company/banner.jpg') no-repeat 100% 100%;
    background-size: 100% 100%;
  }
  .company_info{
    width: 100%;
    height: 843px;
    .company_module1{
      width: 1400px;
      height: 273px;
      margin: 0 auto;
      .company_module1_titile{
        font-family: MicrosoftYaHei;
        font-size: 16px;
        letter-spacing: 0px;
        color: #333333;
        line-height: 16px;
        display: block;
        padding-top: 39px;
      }
      // .company_module1_text{
      //   font-family: MicrosoftYaHei-Bold;
      //   font-size: 68px;
      //   color: #e6e6e6;
      //   display: inline-block;
      //   line-height: 68px;
      //   margin-top: 58px;
      // }
      .company_module1_name{
        font-family: MicrosoftYaHei;
        font-size: 36px;
        width: 100%;
        text-align: center;
        letter-spacing: 4px;
        color: #333333;
        line-height: 36px;
        display: inline-block;
        margin-top: 92px;
      }
    }
    .company_module2{
      width: 100%;
      height: 570px;
      background-color: #f8fafe;
      .company_module2_box{
        width: 1400px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .company_module2_box_text{
          width: 668px;
          height: 301px;
          margin-top: 100px;
          display: inline-block;
          .box_text_time{
            font-family: MicrosoftYaHei;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 2px;
            color: #3179ff;
          }
          .box_text_title{
            font-family: MicrosoftYaHeiLight;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 2px;
            color: #333333;
            margin-top: 19px;
          }
          .box_text_info{
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
            margin-top: 43px;
            line-height: 25px;
            text-indent: 2em
          }
        }
        .company_module2_box_img{
          width: 500px;
          height: 500px;
          background-color: #3179ff;
          position: relative;
        }
        img{
          width: 500px;
          height: 500px;
          position: absolute;
          margin-left: 853px;
          margin-top: -60px;
          transform: scale(1);
          transition: all .5s ease-in-out;
        }
        img:hover {
          transform: scale(1.1);
          &{
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .company_good{
    width: 100%;
    height: 481px;
    margin-top: 59px;
    .company_good_info{
      height: 100%;
      width: 1400px;
      margin:0 auto;
      .company_good_info_module1{
        width: 100%;
        // .company_good_info_module_title{
        //   font-family: MicrosoftYaHei-Bold;
        //   font-size: 68px;
        //   color: #e6e6e6;
        //   position: relative;
        // }
        .company_good_info_module_text{
          font-family: MicrosoftYaHei;
          font-size: 36px;
          letter-spacing: 4px;
          color: #333333;
          width: 100%;
          text-align: center;
          margin-top: 45px;
          display: inline-block;
        }
      }
      .company_good_info_module2{
        height: 350px;
        width: 100%;
        margin-top: 38px;
        display: flex;
        justify-content: space-between;
        .company_good_info_module2_box{
          width: 335px;
          height: 350px;
          border: solid 1px #e5e5e5;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .company_good_info_module2_img{
            width: 52px;
            height: 60px;
            background: url('~@/assets/company/good1.png') no-repeat 100% 100%;
            background-size: 52px 60px;
          }
          .company_good_info_module2_box_span1{
            font-family: MicrosoftYaHei;
            font-size: 24px;
            color: #3179ff;
          }
          .company_good_info_module2_box_span2{
            font-family: MicrosoftYaHeiLight;
            font-size: 14px;
            line-height: 24px;
            color: #333333;
            width: 270px;
            height: 86px;
            display: inline;
          }
        }
        .company_good_info_module2_box:nth-child(2) {
          .company_good_info_module2_img {
            width: 52px;
            height: 60px;
            background: url('~@/assets/company/good2.png') no-repeat 100% 100%;
            background-size: 52px 60px;
          }
        }
        .company_good_info_module2_box:nth-child(3) {
          .company_good_info_module2_img {
            width: 52px;
            height: 60px;
            background: url('~@/assets/company/good3.png') no-repeat 100% 100%;
            background-size: 52px 60px;
          }
        }
        .company_good_info_module2_box:nth-child(4) {
          .company_good_info_module2_img {
            width: 52px;
            height: 60px;
            background: url('~@/assets/company/good4.png') no-repeat 100% 100%;
            background-size: 52px 60px;
          }
        }
        .box_ts {
          background-color: #3179ff;
          position: relative;
          top: -12px;
          .company_good_info_module2_box_span1 {
            color: #fff;
          }
          .company_good_info_module2_box_span2 {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
